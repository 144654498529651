import React          from 'react'
import { Container }  from 'react-bootstrap'
import Typography     from 'entrada-ui/Typography'
import styles         from './styles.module.scss'

const PageTitle = ({ 
  title
}) => {
  return (
    <>
      <Container>
        <Typography variant='h1' className={styles.title}>
          {/* TODO: fix these 'replace' below */}
          {title.replace('&#038;', '&').replace('&#39', "'").replace('&#8216;', "'").replace('&#8216;', "'").replace('&#8211;', '-').replace('&#8211;', '-').replace('&#8217;', "'").replace('&#8217;', "'")}
        </Typography>
      </Container>
    </>
  )
}

export default PageTitle
