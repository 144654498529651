import React                from 'react'
import Img                  from "gatsby-image"
import RawHtml              from 'entrada-ui/RawHtml'
import {
    Container,
    Col,
    Row
}                           from 'react-bootstrap'
import { map }              from 'lodash-es'
import Accordion            from 'entrada-ui/Accordion'
import PageTitle            from 'components/PageTitle'
import Seo                  from 'components/Seo'
import styles               from './styles.module.scss'

const FAQs = ({pageContext : { data }}) => {

    const {
        wordpress_id,
        content,
        title,
        acf: {
            featured_image,
            faq_group
        },
        siteMetadata,
        yoast_meta
    } = data

    return (
        <>
            <Seo meta={yoast_meta} siteMetadata={siteMetadata} />
            <PageTitle title={title} />
            {featured_image &&
            <Container className="generic-hero-container">
                <Img fluid={featured_image.localFile.childImageSharp.fluid} className="generic-hero" />
            </Container>
            }
            
            <Container>
                <RawHtml html={content} />
            </Container>
            
            <Container className={styles.faqGroups}>
                {map(faq_group, (group, idx) => (
                    <div className={styles.faqGroup} key={idx}>
                        <h4 className={styles.groupTitle}>{group.group_title}</h4>
                        <Row>
                            <Col xs={12} lg={9}>
                                <Accordion className={styles.accordion}>
                                {map(group.faqs, (item, idx) => (
                                    <Accordion.Item className={styles.tourAccordionItem} key={idx}>
                                    <Accordion.Toggler>
                                        <Accordion.Title className="text-color-brand-color text-bold">{item.faq_title}</Accordion.Title>
                                    </Accordion.Toggler>

                                    <Accordion.Content>
                                        <RawHtml html={item.faq_content} />
                                    </Accordion.Content>
                                    </Accordion.Item>
                                ))}
                                </Accordion>

                            </Col>
                        </Row>
                    </div>
                ))}
            </Container>

        </>
    )
}

export default FAQs
